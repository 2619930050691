import React, { useState } from "react";
import "./sidebarStyle.css";
import { useForm } from "react-hook-form";
import { RiAddLine, RiSubtractLine } from "react-icons/ri";
import ShopFilter from "../shop-ui/shop-filter/shopFilter";
import { useLocation } from "react-router-dom";
import { isNotUndefined } from "../../../utils/data";
import FilterSwitcher from "../shop-ui/filter-switchers/filterSwitcher";
import FireworksCategoryFilter from "../shop-ui/fireworks-category-filter/fireworksCategoryFilter";

function CustomShopSidebar(props) {
  const {
    allCategories,
    handleCategorySelect,
    allBrands,
    handleBrandSelect,
    setCaliberSelection,
    setPriceSelection,
    resetResults,
    showSidebar,
    setShowSidebar,
    selectedShops,
    setSelectedShops,
    isDiscounted,
    setIsDiscounted,
    isAvailable,
    setIsAvailable,
    onlyAllYear,
    setOnlyAllYear,
    onlyVirtualCart,
    setOnlyVirtualCart,
    selectedFireworksCategories,
    setSelectedFireworksCategories,
  } = props;

  const { handleSubmit, register } = useForm();
  const [filterVisibility, setFilterVisibility] = useState({
    categorySelection: true,
    brandSelection: true,
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const onPriceSubmit = async (data) => {
    const { min_price, max_price } = data;
    setPriceSelection({
      min: min_price === "" ? undefined : min_price,
      max: max_price === "" ? undefined : max_price,
    });
  };

  const onCaliberSubmit = async (data) => {
    const { min_caliber, max_caliber } = data;
    setCaliberSelection({
      min: min_caliber === "" ? undefined : min_caliber,
      max: max_caliber === "" ? undefined : max_caliber,
    });
  };

  const closeSidebar = () => {
    if (showSidebar) {
      document.body.classList.remove("sidebar-active");
      document.body.classList.remove("right-sidebar-active");
      setShowSidebar(false);
    }
  };

  return (
    <aside className="sidebar shop-sidebar right-sidebar sticky-sidebar-wrapper sidebar-fixed">
      {/* Sidebar overlay with Tailwind CSS classes */}
      <div
        className={`fixed inset-0 bg-black/50 z-40 ${
          showSidebar ? "block" : "hidden"
        }`}
        onClick={closeSidebar}
      />

      {/* Close button */}
      <a
        className="sidebar-close"
        onClick={() => {
          closeSidebar();
        }}
      >
        <i className="close-icon" />
      </a>

      {/* Sidebar content */}
      <div
        className="sidebar-content scrollable"
        style={showSidebar ? { transform: "translate(0%)", opacity: "1" } : {}}
      >
        <div className="sticky-sidebar">
          <div className="filter-actions">
            <label>Filter :</label>
            <button
              className="btn btn-rounded"
              onClick={() => {
                resetResults();
              }}
            >
              Zurücksetzen
            </button>
          </div>

          {/* Category Selection */}
          <div className="widget widget-collapsible">
            <h3 className="widget-title" id="categorySelection">
              <span>Kategorien</span>
            </h3>
            <ul>
              {allCategories.map((category, index) => {
                return (
                  <li
                    key={index}
                    className="checkboxContainer"
                    onClick={() => {
                      handleCategorySelect(index);
                    }}
                  >
                    <input
                      type="checkbox"
                      className="myCheckbox"
                      checked={category.value}
                      value={category.value}
                      readOnly={true}
                    />
                    <a className="checkBoxLink">{category.name}</a>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* Price Selection */}
          <div className="widget widget-collapsible">
            <h3 className="widget-title" id="priceSelection">
              <span>Preis</span>
            </h3>
            <div className="widget-body">
              <form
                className="price-range"
                onSubmit={handleSubmit(onPriceSubmit)}
              >
                <input
                  type="number"
                  name="min_price"
                  {...register("min_price")}
                  className="min_price text-center"
                  placeholder="€min"
                  defaultValue={searchParams.get("minprice")}
                />
                <span className="delimiter">-</span>
                <input
                  type="number"
                  name="max_price"
                  {...register("max_price")}
                  className="max_price text-center"
                  placeholder="€max"
                  defaultValue={searchParams.get("maxprice")}
                />
                <button className="btn btn-primary btn-rounded" type="submit">
                  Go
                </button>
              </form>
            </div>
          </div>

          {/* Shop Filter */}
          <div className="widget widget-collapsible">
            <h3 className="widget-title">
              <span>Shops</span>
            </h3>
            <div className="widget-body">
              <ShopFilter
                selectedShops={selectedShops}
                setSelectedShops={setSelectedShops}
              />
            </div>
          </div>

          {/* Fireworks Category Filter */}
          <div className="widget widget-collapsible">
            <h3 className="widget-title">
              <span>Feuerwerkskategorien</span>
            </h3>
            <div className="widget-body">
              <FireworksCategoryFilter
                selectedFireworksCategories={selectedFireworksCategories}
                setSelectedFireworksCategories={setSelectedFireworksCategories}
              />
            </div>
          </div>

          {/* Miscellaneous Filters */}
          <div className="widget widget-collapsible">
            <h3 className="widget-title">
              <span>Sonstiges</span>
            </h3>
            <div className="widget-body">
              <FilterSwitcher
                checked={isDiscounted}
                setChecked={setIsDiscounted}
                text={"Nur rabattierte Produkte zeigen"}
              />
              
                <FilterSwitcher
                  checked={isAvailable}
                  setChecked={setIsAvailable}
                  text={"Nur verfügbare Produkte zeigen"}
                />
             
              <FilterSwitcher
                checked={onlyAllYear}
                setChecked={setOnlyAllYear}
                text={"Nur ganzjährlich bestellbare Produkte zeigen"}
              />
              <FilterSwitcher
                checked={onlyVirtualCart}
                setChecked={setOnlyVirtualCart}
                text={"Nur Produkte für den virtuellen Warenkorb anzeigen"}
              />
            </div>
          </div>

          {/* Brand Selection */}
          <div className="widget widget-collapsible">
            <h3 className="widget-title" id="brandSelection">
              <span>Hersteller</span>
            </h3>

            <ul>
              {allBrands
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((brand, index) => {
                  return (
                    <li
                      key={index}
                      className="checkboxContainer"
                      onClick={() => {
                        handleBrandSelect(index);
                      }}
                    >
                      <input
                        type="checkbox"
                        className="myCheckbox"
                        checked={brand.value}
                        readOnly={true}
                      />
                      <a className="checkBoxLink">{brand.name}</a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </aside>
  );
}

export default CustomShopSidebar;
